import React from "react"

import LogoImage from "../assets/images/logo-white.png"
import MenuIcon from "../assets/images/menu.png"
import PhoneIcon from "../assets/images/phone.png"
import Link from "../utils/link"
import MobileMenu from "./MobileMenu"

const Header = () => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
  }

  const listOfClass = ["menu__desktop"]

  if (isVisible) {
    listOfClass.push("show-menu")
  }

  const menuClass = listOfClass.join(" ")

  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100
      if (isScrolled) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <header className={scrolled ? "header header-fixed" : "header"} id="header">
      <div className="header__mainContainer">
        <div className="header__container">
          <Link to="/" title="Vacuum Truck Supplies" className="header__logo">
            <img src={LogoImage} alt="Vacuum Truck Supplies" />
          </Link>
          <div className="menu">
            <div className={menuClass}>
              <div className="menu__mails">
                <span>General:</span>
                <Link to="mailto:info@vacuumtrucks.com.au">
                  info@vacuumtrucks.com.au
                </Link>
                <span>Sales:</span>
                <Link to="mailto:sales@vacuumtrucks.com.au">
                  sales@vacuumtrucks.com.au
                </Link>
              </div>
              <nav className="nav">
                <ul>
                  <li>
                    <a href="#why-choose" onClick={() => setIsVisible(false)}>
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#about-custom" onClick={() => setIsVisible(false)}>
                      our units
                    </a>
                  </li>
                  <li>
                    <a href="#process" onClick={() => setIsVisible(false)}>
                      our process
                    </a>
                  </li>
                  <li>
                    <a href="#contact" onClick={() => setIsVisible(false)}>
                      contact
                    </a>
                  </li>
                </ul>
              </nav>
              <Link to="tel:+1800 822 787" className="btn btn__primary phone">
                <img src={PhoneIcon} alt="Phone Icon" />
                <span>1800 822 787</span>
              </Link>
              <div className="menu__header">
                <Link
                  to="/"
                  title="Vacuum Truck Supplies"
                  className="header__logo"
                >
                  <img src={LogoImage} alt="Vacuum Truck Supplies" />
                </Link>
                <button
                  className="btn toggler"
                  onClick={() => toggleIsVisible()}
                >
                  &times;
                </button>
              </div>
            </div>
            <div className="menu__mobile">
              <Link to="tel:+1800 822 787" className="btn btn__primary phone">
                <img src={PhoneIcon} alt="Phone Icon" />
              </Link>
              <button className="btn toggler" onClick={() => toggleIsVisible()}>
                <img src={MenuIcon} alt="Menu Icon" />
              </button>
            </div>
            {isVisible ? <MobileMenu /> : ""}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
