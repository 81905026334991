import React from "react"

import useLockBodyScroll from "../utils/lockScroll"

const MobileMenu = () => {
  useLockBodyScroll()

  return <span>&nbsp;</span>
}

export default MobileMenu
