import React from "react"

import Link from "../utils/link"
import Dropdown from "./Dropdown"
import Header from "./header"

const options = [
  { label: "More Information", value: "information" },
  { label: "Truck/Trailer Quote", value: "truck-trailer" },
  { label: "Portable Unit Quote", value: "portable" },
  { label: "Custom Build Quote", value: "custom-buils" },
  { label: "Parts Enquiry", value: "parts-enquery" },
]

const Footer = () => {
  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    document.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 640)
    })
  }, [])

  return (
    <footer className="footer">
      <div className="contact">
        <div className="contact__container">
          <div className="contact__inner-container">
            <div className="contact__details">
              <h2>Contact Us</h2>
              <p>
                While our head office is in Mansfield, Victoria, we have the
                capabilities and experience to service and build trucks
                Australia wide. Call, email or fill out our form to get in
                touch. We’re here to help.
              </p>

              {!isMobile ? (
                <div className="contact__desktop">
                  <div className="contact__mode border-bottom">
                    <div>
                      <h5>Phone</h5>
                      <Link to="tel:1800822787">
                        <p>1800 822 787</p>
                      </Link>
                      <Link to="tel:0357757300">
                        <p>(03) 5775 7300</p>
                      </Link>
                    </div>
                    <div>
                      <h5>Email</h5>
                      <Link to="mailto:info@vacuumtrucks.com.au">
                        <p>
                          <span>General:</span> info@vacuumtrucks.com.au
                        </p>
                      </Link>
                      <Link to="mailto:sales@vacuumtrucks.com.au">
                        <p>
                          <span>Sales:</span> sales@vacuumtrucks.com.au
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="contact__mode">
                    <div>
                      <h5>Mail</h5>
                      <p>
                        Vacuum Truck Supplies PO Box 556 Mansfield Victoria 3724
                        AUSTRALIA
                      </p>
                    </div>
                    <div>
                      <h5>Deliveries</h5>
                      <p>
                        Vacuum Truck Supplies 18 Crosbys Lane Mansfield Victoria
                        3722 AUSTRALIA
                      </p>
                    </div>
                  </div>
                  <div className="contact__mode">
                    <div>
                      <h5>Fax</h5>
                      <Link to="tel:0357751488">
                        <p>(03) 5775 1488</p>
                      </Link>
                    </div>
                    <div className="google-map">
                      <Link to="https://www.google.com/maps/place/18+Crosbys+Ln,+Mansfield+VIC+3722/@-37.0647006,146.0982062,17z/data=!3m1!4b1!4m5!3m4!1s0x6b27c94501813773:0xcc3dc7d3b921b414!8m2!3d-37.0647006!4d146.1004002">
                        View on Google Maps
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="contact__mobile">
                  <div className="contact__mode">
                    <div>
                      <h5>Phone</h5>
                      <Link to="tel:1800822787">
                        <p>1800 822 787</p>
                      </Link>
                      <Link to="tel:0357757300">
                        <p>(03) 5775 7300</p>
                      </Link>
                    </div>
                    <div>
                      <h5>Fax</h5>
                      <Link to="tel:0357751488">
                        <p>(03) 5775 1488</p>
                      </Link>
                    </div>
                  </div>
                  <div className="border-bottom">
                    <h5>Email</h5>
                    <Link to="mailto:info@vacuumtrucks.com.au">
                      <p>
                        <span>General:</span> info@vacuumtrucks.com.au
                      </p>
                    </Link>
                    <Link to="mailto:sales@vacuumtrucks.com.au">
                      <p>
                        <span>Sales:</span> sales@vacuumtrucks.com.au
                      </p>
                    </Link>
                  </div>
                  <div className="contact__mode">
                    <div>
                      <h5>Mail</h5>
                      <p>
                        Vacuum Truck Supplies PO Box 556 Mansfield Victoria 3724
                        AUSTRALIA
                      </p>
                    </div>
                    <div className="google-map">
                      <Link to="https://www.google.com/maps/place/18+Crosbys+Ln,+Mansfield+VIC+3722/@-37.0647006,146.0982062,17z/data=!3m1!4b1!4m5!3m4!1s0x6b27c94501813773:0xcc3dc7d3b921b414!8m2!3d-37.0647006!4d146.1004002">
                        View on Google Maps
                      </Link>
                    </div>
                  </div>
                  <div className="contact__mode">
                    <div>
                      <h5>Deliveries</h5>
                      <p>
                        Vacuum Truck Supplies 18 Crosbys Lane Mansfield Victoria
                        3722 AUSTRALIA
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <form
              className="form"
              action="https://formspree.io/f/xvolkoze"
              method="POST"
            >
              <div className="form__row">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name*"
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name*"
                  required
                />
              </div>
              <div className="form__fluid">
                <input
                  type="email"
                  name="_replyto"
                  id="email"
                  placeholder="Email Address*"
                  required
                />
              </div>
              <div className="form__fluid">
                <input
                  type="company"
                  name="company"
                  id="company"
                  placeholder="Your Company/Business*"
                  required
                />
              </div>
              <div className="form__fluid">
                <Dropdown
                  name="help_with"
                  options={options}
                  placeholder="I’d like help with..."
                />
              </div>
              <div className="form__fluid">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Your enquiry*"
                  cols="30"
                  rows="6"
                ></textarea>
              </div>
              <div className="form__fluid">
                <button className="btn btn__secondary">Send enquiry</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Header />
      <div className="footer__mainContainer">
        <div className="footer__container">
          <div>
            <small>
              © Copyright {new Date().getFullYear()} Vacuum Truck Supplies. All
              rights reserved.
            </small>
            <small>
              <Link to="https://atollon.com.au/">Design — Atollon</Link>
            </small>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
